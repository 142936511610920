//======================================================================================================
// GENERAL
//======================================================================================================
.home {
    &-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: $font-family--heading;
        font-size: $font-size--1;
        line-height: 1.2em;
        font-weight: 400;
        color: $color-white;
        text-align: center;
        margin: 0;

        &::before {
            display: none;
        }

        span {
            position: relative;
            font-family: $font-family;
            font-size: $font-size--text-medium;
            line-height: 1.25em;
            text-transform: uppercase;

            &::before,
            &::after {
                content: '';
                width: 50px;
                height: 2px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: $color-white;
            }

            &::before {
                left: -60px;
            }

            &::after {
                right: -60px;
            }
        }

        &--black {
            color: $color-text;

            span {
                &::before,
                &::after {
                    background-color: $color-text;
                }
            }
        }
    }

    &-button {
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-size--text-small;
        line-height: 1.45em;
        font-weight: 700;
        color: $color-white;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        border: 1px solid $color-white;
        border-radius: 50px;

        &:hover,
        &:focus {
            color: $color-text;
            background-color: $color-white;
            border-color: $color-white;

            svg {
                fill: $color-text;
            }
        }

        &--black {
            color: $color-text;
            border-color: $color-text;

            &:hover,
            &:focus {
                color: $color-white;
                background-color: $color-text;
                border-color: $color-text;
            }
        }

        &--second {
            color: $color-text;
            background-color: $color-third;
            border-color: $color-third;

            &:hover,
            &:focus {
                color: $color-white;
                background-color: $color-text;
                border-color: $color-text;
            }
        }
    }
}





//======================================================================================================
// ALU
//======================================================================================================
.alu {
    width: 100%;
    position: relative;
    overflow: hidden;

    &--video {
        img {
            display: none;
        }
    }

    &__item {
        width: 100%;
        height: 500px !important;
        position: relative;
        display: flex;

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.225) 25.52%, rgba(0, 0, 0, 0.025) 66.15%, rgba(0, 0, 0, 0) 100%)
        }
    }

    &__media {
        width: 100%;
        height: 100%;
        background-color: $color-bg--image;

        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__content {
        position: absolute;
        right: calc((100% - 1170px) / 2);
        bottom: 50px;
        z-index: 2;
    }

    &__title {
        max-width: 300px;
        font-family: $font-family;
        font-size: $font-size--4;
        line-height: 1.1em;
        font-weight: $font-weight-semibold;
        color: $color-white;
        text-align: right;
        text-shadow: 0px 2px 15px rgba(0, 0, 0, .7);
        margin: 0;

        &::after {
            display: none;
        }
    }

    &-navigation {
        position: absolute;
        top: 50%;
        left: calc((100% - 1170px) / 2);
        transform: translateY(-50%);
        z-index: 2;
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        &__item {
            width: 40px;
            height: 40px;
            background-color: transparent;
            border: 1px solid $color-white;
            border-radius: $border-radius--round;
            padding: 0;
            margin: 0;
            transition: $duration;

            &:hover,
            &:focus {
                background-color: $color-white;
                border-color: $color-white;

                svg {
                    fill: $color-text;
                }
            }

            svg {
                width: 100%;
                height: 100%;
                fill: $color-white;
                transition: $duration;
            }
        }
    }
}





//======================================================================================================
// ACCES RAPIDES
//======================================================================================================
.ar {
    background-color: $color-main;
    padding: 60px 0 100px;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 50px;
    }

    &__items {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &-item {
        width: 110px;
        height: 115px;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &::before {
            content: '';
            width: 40px;
            height: 40px;
            position: absolute;
            z-index: -1;
            background-color: $color-second;
            border-radius: $border-radius--round;
            transition: $duration;
        }

        &:hover,
        &:focus {
            &::before {
                background-color: $color-third;
            }
        }

        &:first-child::before {
            top: 0;
            left: 25px;
        }

        &:nth-child(2)::before {
            top: 15px;
            left: -10px;
        }

        &:nth-child(3)::before {
            top: 10px;
            left: 30px;
        }

        &:nth-child(4)::before {
            top: 0;
            left: -10px;
        }

        &:nth-child(5)::before {
            top: 30px;
            left: -5px;
        }

        &:nth-child(6)::before {
            top: 0;
            left: 25px;
        }

        &:nth-child(7)::before {
            top: 35px;
            left: -15px;
        }

        &:nth-child(8)::before {
            top: 0;
            left: 25px;
        }

        &__icon {
            width: 70px;
            height: 70px;
            fill: $color-white;
            transition: $duration;
        }

        &__title {
            font-size: $font-size--text-small;
            line-height: 1.2em;
            font-weight: 700;
            color: $color-white;
            margin: 0;

            &::after {
                display: none;
            }

            span {
                font-weight: 400;
                transition: $duration;
            }
        }
    }
}





//======================================================================================================
// ACTUALITES
//======================================================================================================
.ac {
    position: relative;
    padding: 90px 0 100px;
    overflow: hidden;

    &::before {
        content: '';
        width: 2000px;
        height: 100%;
        position: absolute;
        top: 0;
        right: calc(100% - ((100% - 1170px) / 2) -  270px);
        background-color: $color-third;
        z-index: -1;
    }

    &::after {
        content: '';
        width: 220px;
        height: 220px;
        position: absolute;
        right: calc(100% - ((100% - 1170px) / 2) + 10px);
        bottom: -40px;
        background-color: rgba($color-white, .5);
        border-radius: $border-radius--round;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__content {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;

        &::before {
            content: '';
            width: 2000px;
            height: 100%;
            position: absolute;
            top: 0;
            right: calc(100% - 200px);
            background-color: $color-third;
            z-index: 2;
        }
    }

    &-item {
        width: 370px !important;
        height: 510px !important;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        &:hover,
        &:focus {
            .ac-item{
                &__more::after {
                    width: 50px;
                    background-color: $color-main;
                }
            }

            
        }

        &__image {
            width: 100%;
            height: 250px;
            flex-shrink: 0;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
            }
        }

        &__content {
            height: 260px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: $color-white;
            padding: 30px 10px 30px 30px;
            overflow: hidden;
        }

        &__category {
            display: flex; 
            align-items: center;
            column-gap: 10px;
            font-size: $font-size--text-small;
            line-height: 1.45em;
            font-weight: 700;
            color: $color-text;
            text-transform: uppercase;

            &::before {
                content: '';
                width: 30px;
                height: 2px;
                background-color: $color-text;
            }
        }

        &__title {
            font-size: $font-size--4;
            line-height: 1.1em;
            font-weight: 700;
            color: $color-text;
            margin: 15px 0 0;
            transition: $duration;

            &::after {
                display: none;
            }
        }

        &__text {
            line-height: 1.5em;
            color: $color-text; 
            margin-top: 12.5px;
        }

        &__more {
            position: relative;
            z-index: 1;
            font-weight: 700;
            color: $color-text;
            margin: auto 0 0;
            opacity: 1;

            &::after {
                content: '';
                width: 240px;
                height: 2px;
                position: absolute;
                top: 50%;
                left: calc(100% + 15px);
                transform: translateY(-50%);
                background-color: $color-second;
                transition: $duration;
            }
        }
    }


    &-navigation {
        position: relative;
        z-index: 3;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        &__item {
            width: 50px;
            height: 50px;
            background-color: transparent;
            border: 1px solid $color-text;
            border-radius: $border-radius--round;
            padding: 0;
            margin: 0;
            transition: $duration;

            &:hover,
            &:focus {
                background-color: $color-text;
                border-color: $color-text;

                svg {
                    fill: $color-white;
                }
            }

            svg {
                width: 100%;
                height: 100%;
                fill: $color-text;
                transition: $duration;
            }
        }
    }

    &__button {
        width: 370px;
        margin-top: 40px;
    }


    .swiper-container {
        width: 970px;
        margin: 0;
        overflow: visible;
    }
}





//======================================================================================================
// AGENDA
//======================================================================================================
.ag {
    background-color: $color-main;
    padding: 110px 0 100px;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        line-height: 1.4em;
    }

    &__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
    }

    &__smallitems {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }

    // Commun styles to big and small items
    &-item {
        &__infos {
            flex: 1;
            display: flex;
            flex-direction: column;
            row-gap: 15px;
        }

        &__title {
            font-size: $font-size--4;
            line-height: 1.1em;
            font-weight: 700;
            color: $color-white;
            margin: 0;

            &::after {
                display: none;
            }
        }

        &__separator {
            width: 350px;
            height: 2px;
            background-color: $color-second;
            transition: $duration;

            &--small {
                width: 250px;
            }
        }

        &__location-time {
            display: flex;
            flex-direction: column;
            row-gap: 5px;
        }

        &__location,
        &__time {
            display: flex;
            align-items: center;
            column-gap: 5px;

            svg {
                width: 25px;
                height: 25px;
                flex-shrink: 0;
                fill: $color-white;
            }

            p {
                font-style: italic;
                color: $color-white;
            }
        }
    }

    &-bigitem {
        width: 570px;
        height: 530px;
        display: flex;
        flex-direction: column;

        &:hover,
        &:focus {
            .ag-item__separator {
                width: 125px;
                background-color: $color-third;
            }

            .ag-bigitem__content::before {
                background-color: $color-third;
            }
        }

        &__image {
            width: 100%;
            height: 320px;
            flex-shrink: 0;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-events.png) $color-bg--image no-repeat center;
            }
        }

        &__content {
            height: 210px;
            position: relative;
            display: flex;
            align-items: center;
            column-gap: 55px;
            background-color: rgba($color-white, .1);
            padding: 10px 30px 10px 55px;
            overflow: hidden;

            &::before {
                content: '';
                width: 60px;
                height: 60px;
                position: absolute;
                left: -15px;
                bottom: -15px;
                background-color: $color-second;
                border-radius: $border-radius--round;
                transition: $duration;
            }
        }  
    }

    &-smallitem {
        width: 570px;
        height: 250px;
        display: flex;
        column-gap: 30px;
        align-items: center;

        &:hover,
        &:focus {
            .ag-item__separator {
                width: 125px;
                background-color: $color-third;
            }

            .ag-smallitem__dates::before {
                background-color: $color-third;
            }
        }

        &:first-child .ag-smallitem__dates::before {
            right: -15px;
            bottom: -15px;
        }

        &:nth-child(2) .ag-smallitem__dates::before {
            top: 70px;
            left: -30px;
        }

        &__dates {
            width: 150px;
            height: 100%;
            position: relative;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba($color-white, .1);
            overflow: hidden;

            &::before {
                content: '';
                width: 60px;
                height: 60px;
                position: absolute;
                background-color: $color-second;
                border-radius: $border-radius--round;
                transition: $duration;
            }
        }
    }

    &-date {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 15px;

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;

            &__day {
                font-family: $font-family--heading;
                font-size: 40px;
                line-height: 1em;
                font-weight: 400;
                color: $color-white;
            }

            &__month {
                font-size: $font-family;
                font-size: 25px;
                line-height: 1.2em;
                font-weight: 400;
                color: $color-white;
                text-transform: uppercase;
            }
        }

        &__separator {
            width: 50px;
            height: 2px;
            background-color: $color-white;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        column-gap: 30px;
        margin-top: 40px;
    }

    &__button {
        width: 370px;
    }
}





//======================================================================================================
// KIOSQUE + NEWSLETTER
//======================================================================================================
.kio-newl {
    position: relative;
    padding: 105px 0 100px;

    &::after {
        content: '';
        width: 3000px;
        height: 100%;
        position: absolute;
        top: 0;
        left: calc(100% - ((100% - 1170px) / 2) - 515px);
        z-index: -1;
        background-color: $color-third;
    }

    &__container {
        display: flex;
        column-gap: 80px;
    }
}



.kio {
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 30px;

    &__title {
        line-height: 1.45em;
        margin-left: 40px;
    }

    &-item {
        display: flex;
        align-items: flex-end;
        margin-top: -77.5px;

        &__content {
            width: 515px;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 40px;
        }

        &__infos {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            row-gap: 20px;
            background-color: $color-third;
            padding: 30px 40px;
        }

        &__title {
            font-size: $font-size--4;
            line-height: 1.15em;
            font-weight: 700;
            color: $color-text;
            margin: 0;

            &::after {
                display: none;
            }
        }

        &__text {
            font-size: $font-size--text-small;
            line-height: 1.45em;
            font-style: italic;
            color: $color-text;

            span {
                text-transform: uppercase;
            }
        }

        &__buttons {
            display: flex;
            column-gap: 10px;
        }

        &__button {
            width: 30px;
            height: 30px;
            background-color: $color-main;
            border-radius: $border-radius--round;
            transition: $duration;

            &:hover,
            &:focus {
                background-color: $color-second;

                svg {
                    fill: $color-text;
                }
            }

            svg {
                width: 100%;
                height: 100%;
                fill: $color-white;
                transition: $duration;
            }

            &--listen {
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 14px;
                    height: 14px;
                }
            } 
        }

        &__image {
            width: 285px;
            height: 400px;
            background-color: $color-bg--image;
            filter: drop-shadow(0px 1px 10px rgba(1, 109, 120, 0.1));

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-documents.png) $color-bg--image no-repeat center;
            }
        }
    }

    &__button {
        width: 370px;
    }
}



.newl {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    &__icon {
        width: 70px;
        height: 70px;
        position: relative;
        flex-shrink: 0;
        margin-left: 10px;

        &::before {
            content: '';
            width: 60px;
            height: 60px;
            position: absolute;
            top: -25px;
            left: -10px;
            background-color: $color-main;
            border-radius: $border-radius--round;
        }

        svg {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
            fill: $color-text;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__title {
        font-size: $font-size--4;
        line-height: 1.15em;
        font-weight: 700;
        color: $color-text;
        margin: 40px 0 0;

        &::after {
            width: 150px;
            background-color: $color-main;
        }
    }

    &__text {
        max-width: 200px;
        line-height: 1.6em;
        color: $color-text;
        margin-top: 25px;
    }

    &__button {
        width: 220px;
        margin-top: 40px;
    }
}





//======================================================================================================
// RESPONSIVE
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        //======================================================================================================
        // GENERAL
        //======================================================================================================
        .home-title {
            font-size: 40px;
        }






        //======================================================================================================
        // ALU
        //======================================================================================================
        .alu {
            &__item {
                height: 400px !important;
            }

            &__content {
                right: calc((100% - 940px) / 2);
                bottom: 40px;
            }

            &-navigation {
                left: calc((100% - 940px) / 2);
                row-gap: 15px;
            }
        }





        //======================================================================================================
        // ACCES RAPIDES
        //======================================================================================================
        .ar {
            padding: 55px 0 100px;

            &-item {
                width: 100px;
            }
        }





        //======================================================================================================
        // ACTUALITES
        //======================================================================================================
        .ac {
            padding: 102.5px 0 100px;
        
            &::before {
                right: calc(100% - ((100% - 940px) / 2) - 215px);
            }
        
            &::after {
                right: calc(100% - ((100% - 940px) / 2) - 20px);
            }
        
            &__content::before {
                right: calc(100% - 160px);
            }
        
            &-item {
                width: 300px !important;
                height: 465px !important;
        
                &__image {
                    height: 230px;
                }
        
                &__content {
                    height: 235px;
                    padding: 20px 10px 20px 20px;
                }

                &__title {
                    font-size: $font-size--text-medium;
                    line-height: 1.25em;
                }
        
                &__text {
                    font-size: $font-size--text-small;
                    line-height: 1.45em;
                    margin-top: 10px;
                }
        
                &__more {
                    font-size: $font-size--text-small;

                    &::after {
                        width: 190px;
                    }
                }
            }
        
        
            &-navigation {
                row-gap: 15px;
            }
        
            &__button {
                width: 300px;
            }
        
        
            .swiper-container {
                width: 780px;
            }
        }





        //======================================================================================================
        // AGENDA
        //======================================================================================================
        .ag {
            padding: 107.5px 0 100px;
        
            &__smallitems {
                row-gap: 20px;
            }
    
            &-item {        
                &__title {
                    font-size: $font-size--text-medium;
                    line-height: 1.2em;
                }
        
                &__separator {
                    width: 280px;

                    &--small {
                        width: 250px;
                    }
                }
            }
        
            &-bigitem {
                width: 460px;
                height: 470px;
        
                &__image {
                    height: 260px;
                }
        
                &__content {
                    column-gap: 40px;
                    padding: 10px 30px 10px 45px;
                }  
            }
        
            &-smallitem {
                width: 460px;
                height: 225px;
                column-gap: 20px;
            }
        
            &__buttons {
                column-gap: 20px;
            }
        }





        //======================================================================================================
        // KIOSQUE + NEWSLETTER
        //======================================================================================================
        .kio-newl {
            padding: 120px 0 100px;
        
            &::after {
                left: calc(100% - ((100% - 960px) / 2) - 530px);
            }
        
            &__container {
                column-gap: 40px;
            }
        }
        
        
        
        .kio {
            width: 680px;
            margin-left: 0;
        
            &__title {
                margin-left: 30px;
            }
        
            &-item {        
                &__content {
                    width: 410px;
                    row-gap: 30px;
                }
        
                &__infos {
                    padding: 30px;
                }
        
                &__title {
                    font-size: $font-size--text-medium;
                    line-height: 1.25em;
                }
        
                &__image {
                    width: 270px;
                    height: 380px;
                }
            }
        
            &__button {
                width: 300px;
            }
        }
        
        
        
        .newl {                
            &__title {
                font-size: $font-size--text-medium;
                line-height: 1.25em;
                margin-top: 30px;
            }
        
            &__text {
                font-size: $font-size--text-small;
                line-height: 1.45em;
                margin-top: 25px;
            }
        
            &__button {
                margin-top: 30px;
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        //======================================================================================================
        // GENERAL
        //======================================================================================================
        .home-title {
            font-size: 35px;
        }






        //======================================================================================================
        // ALU
        //======================================================================================================
        .alu {
            &__item {
                height: 320px !important;
            }

            &__content {
                right: calc((100% - 620px) / 2);
                bottom: 30px;
            }

            &__title {
                font-size: $font-size--text;
                line-height: 1.25em;
            }

            &-navigation {
                left: calc((100% - 620px) / 2);
            }
        }





        //======================================================================================================
        // ACCES RAPIDES
        //======================================================================================================
        .ar {
            padding: 62.5px 0 80px;
    
            &__items {
                flex-wrap: wrap;
                column-gap: 70px;
                row-gap: 30px;
            }
        }





        //======================================================================================================
        // ACTUALITES
        //======================================================================================================
        .ac {
            padding: 95px 0 80px;
        
            &::before {
                right: calc(100% - ((100% - 620px) / 2) - 110px);
            }
        
            &::after {
                right: calc(100% - ((100% - 620px) / 2) + 20px);
                bottom: -45px;
            }
        
            &__content {
                flex-direction: column;
                row-gap: 30px;
                margin-top: 40px;

                &::before {
                    right: 100%;
                }
            }
        
            &-navigation {
                flex-direction: row;
                column-gap: 20px;

                &__item {
                    width: 40px;
                    height: 40px;
                }
            }        
        
            .swiper-container {
                width: 100%;
            }
        }





        //======================================================================================================
        // AGENDA
        //======================================================================================================
        .ag {
            padding: 95px 0 80px;

            &__content {
                flex-direction: column;
                row-gap: 20px;
            }
        
            &__smallitems {
                flex-direction: row;
                column-gap: 20px;
            }
    
            &-item__separator {
                width: 350px;

                &--small {
                    width: 250px;
                }
            }
        
            &-bigitem {
                width: 100%;
                height: 510px;
        
                &__image {
                    height: 300px;
                }
        
                &__content {
                    column-gap: 60px;
                }  
            }
        
            &-smallitem {
                width: 300px;
                height: auto;
                flex-direction: column;
                align-items: stretch;
                row-gap: 15px;

                &:nth-child(2) .ag-smallitem__dates:before {
                    top: -10px;
                    right: -15px;
                    left: unset;
                }

                &__dates {
                    width: 100%;
                    height: 150px;
                }

                .ag-date {
                    flex-direction: row;
                    column-gap: 15px;

                    &__separator {
                        width: 2px;
                        height: 50px;
                    }
                }
            }

            &__button {
                width: 300px;
            }
        }





        //======================================================================================================
        // KIOSQUE + NEWSLETTER
        //======================================================================================================
        .kio-newl {
            padding: 0;
        
            &::after {
                display: none;
            }
        
            &__container {
                flex-direction: column;
            }
        }
        
        
        
        .kio {
            width: 100%;
            padding: 90px 0 80px;
        
            &__title {
                margin-left: 0;
            }
        
            &-item { 
                margin-top: -65px;

                &__content {
                    width: 350px;
                    row-gap: 40px;
                }
        
                &__infos {
                    position: relative;
                    padding: 25px 30px 25px 0;

                    &::before {
                        content: '';
                        width: 30px;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: -30px;
                        z-index: -1;
                        background-color: $color-third;
                    }
                }
            }
        }
        
        
        
        .newl {   
            position: relative;
            flex-direction: row;
            justify-content: center;
            column-gap: 30px;
            padding: 50px 0; 

            &::after {
                content: '';
                width: 3000px;
                height: 100%;
                position: absolute;
                top: 0;
                left: -1000px;
                z-index: -1;
                background-color: $color-third;
            }

            &__icon {
                margin-top: 25px;
            }
            
            &__title {
                margin-top: 0;
            }

            &__text {
                max-width: 400px;
                margin-top: 20px;
            }

            &__button {
                width: 350px;
            }
        }
    }



    // 640
    @media screen and (max-width: $small) {
        //======================================================================================================
        // GENERAL
        //======================================================================================================
        .home-title span {
            &::before,
            &::after {
                width: 20px;
            }

            &::before {
                left: -30px;
            }

            &::after {
                right: -30px;
            }
        }






        //======================================================================================================
        // ALU
        //======================================================================================================
        .alu {
            &__item {
                height: 280px !important;
            }

            &__content {
                right: calc((100% - 300px) / 2);
                bottom: 20px;
            }

            &-navigation {
                left: calc((100% - 300px) / 2);
            }
        }





        //======================================================================================================
        // ACCES RAPIDES
        //======================================================================================================
        .ar__items {
            justify-content: center;
            column-gap: 60px;
        }





        //======================================================================================================
        // ACTUALITES
        //======================================================================================================
        .ac {        
            &::before {
                right: calc(100% - ((100% - 300px) / 2) - 30px);
            }
        
            &::after {
                right: calc(100% - ((100% - 300px) / 2) + 20px);
                bottom: -50px;
            } 

            &__button {
                background-color: $color-bg--body;
            }
        }





        //======================================================================================================
        // AGENDA
        //======================================================================================================
        .ag {        
            &__smallitems {
                flex-direction: column;
            }
    
            &-item__separator {
                width: 250px;
            }
        
            &-bigitem {
                height: auto;
        
                &__image {
                    height: 170px;
                }
        
                &__content {
                    height: auto;
                    flex-direction: column;
                    row-gap: 40px;
                    padding: 40px 20px 30px;

                    &::before {
                        top: 50px;
                        bottom: unset;
                    }
                }  

                .ag-date {
                    flex-direction: row;
                    column-gap: 15px;

                    &__separator {
                        width: 2px;
                        height: 50px;
                    }
                }
            }
        
            &-smallitem {
                &:nth-child(2) .ag-smallitem__dates:before {
                    top: unset;
                    right: unset;
                    left: -10px;
                    bottom: -15px;
                }

                &__dates {
                    width: 100%;
                    height: 150px;
                }
            }

            &__buttons {
                flex-direction: column;
                row-gap: 20px;
            }

            &__button {
                width: 300px;
            }
        }





        //======================================================================================================
        // KIOSQUE + NEWSLETTER
        //======================================================================================================      
        .kio {        
            &-item { 
                flex-direction: column-reverse;
                align-items: center;
                margin-top: 45px;

                &__content {
                    width: 100%;
                }
        
                &__infos {
                    padding: 30px 0;

                    &::before {
                        width: 1000px;
                        height: calc(100% + 25px);
                        top: -25px;
                    }
                }
            }
        }
        
        
        
        .newl {   
            justify-content: flex-start;
            column-gap: 20px;
            padding-bottom: 135px;

            &__text {
                max-width: auto;
                margin-top: 20px;
            }

            &__button {
                width: 300px;
                position: absolute;
                left: 50%;
                bottom: 50px;
                transform: translateX(-50%);
                margin-top: 0;
            }
        }
    }