.footer {
    background-color: $color-main;

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        padding: 80px 0 75px;
    }

    &__logo-contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 25px;
    }

    &__logo {
        width: 160px;
        height: 70px;

        svg {
            width: 100%;
            height: 100%;
            fill: $color-white;
        } 
    }

    &__contact {
        width: 250px;
        height: 50px !important;
        column-gap: 10px;

        svg {
            width: 30px;
            height: 30px;
            fill: $color-white;
            transition: $duration;
        }
    }

    &__mairie,
    &__horaires {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__title {
        font-size: $font-size--4;
        font-weight: 700;
        color: $color-white;
        margin: 0;
    }

    &__text {
        color: $color-white;
        margin-top: 20px;
    }

    &__tel {
        display: block;
        margin-top: 30px;
        transition: $duration;

        &:hover,
        &:focus {
            color: $color-second;
        }
    }

    &-labels {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        column-gap: 40px;

        &__lorient {
            width: 134px;
            height: 45px;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &__france {
            display: flex;
            align-items: center;
            column-gap: 10px;

            span {
                width: 60px;
                font-size: $font-size--text-small;
                line-height: 1.45em;
                font-style: italic;
                color: $color-white;
                text-align: right;
            }

            img {
                width: auto;
                height: 45px;
            }
        }
    }

    &__bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        border-top: 2px solid $color-white;
        padding: 30px 0;
    }

    &-menu {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 70px;
        row-gap: 20px;

        &__item {
            position: relative;

            &::after {
                content: '';
                width: 20px;
                height: 2px;
                position: absolute;
                top: 50%;
                right: -45px;
                transform: translateY(-50%);
                background-color: $color-white;
            }

            &:last-child::after {
                display: none;
            }
        }

        &__link {
            font-size: $font-size--text-small;
            line-height: 1.45em;
            color: $color-white;
            padding: 5px 0;
            transition: $duration;

            &:hover,
            &:focus {
                color: $color-second;
            }
        }
    }

    &__fleur {
        width: 105px;
        height: 45px;
        flex-shrink: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}





// 1200
@media screen and (max-width: $large) {
    .footer {     
        &__content {
            align-items: center;
            padding: 75px 0 75px;
        }
        
        &__logo {
            width: 130px;
            height: 60px;
        }
    
        &__contact {
            width: 180px;
        }
    
        &__mairie,
        &__horaires {
            width: 230px;
        }

        &__mairie {
            margin-left: 50px;
        }

        &__horaires {
            margin-left: 20px;
        }

        &__title {
            font-size: $font-size--text-medium;
        }
    
        &__text {
            font-size: $font-size--text-small;
            margin-top: 20px;
        }
    
        &__tel {
            margin-top: 20px;
        }
    
        &-labels {
            margin-top: 30px;
            height: 45px;
        }
    
        &__bottom {
            column-gap: 100px;
        }
    
        &-menu {
            justify-content: center;
            column-gap: 100px;
    
            &__item {
                &::after {
                    right: -60px;
                }

                &:nth-child(4)::after {
                    display: none;
                }
            }
        }
    }
}



// 960
@media screen and (max-width: $medium) {
    .footer {         
        &__content {
            flex-direction: column;
            row-gap: 32.5px;
            padding: 80px 0 45px;
        }

        &__logo-contact {
            row-gap: 30px;
        }

        &__contact {
            width: 250px;
        }
    
        &__mairie,
        &__horaires {
            width: 300px;
            align-items: center;
            margin-left: 0;
        }
    
        &__text {
            text-align: center;
            margin-top: 10px;
        }
    
        &__tel {
            margin-top: 10px;
        }
    
        &-labels {
            flex-direction: row;
            justify-content: center;
            align-items: center;
            column-gap: 45px;
        }
    
        &__bottom {
            column-gap: 0;
            padding: 35px 0;
        }
    
        &-menu {
            column-gap: 55px;
    
            &__item {
                &::after {
                    right: -37.5px;
                }

                &:nth-child(3) {
                    &:after {
                        display: none;
                    }
                }

                &:nth-child(4) {
                    &:after {
                        display: block;
                    }
                }
            }
        }
    }
}



// 640
@media screen and (max-width: $small) {
    .footer {         
        &-labels {
            width: 100%;
            justify-content: space-between;
            column-gap: 0;
            flex-direction: column;
            row-gap: 30px;
            height: auto;
        }
    
        &__bottom {
            position: relative;
            padding: 40px 0;
        }
    
        &-menu {
            column-gap: 60px;
            row-gap: 27.5px;
    
            &__item {
                &::after {
                    right: -40px;
                }

                &:nth-child(3)::after {
                    display: block;
                }

                &:nth-child(1)::after,
                &:nth-child(2)::after,
                &:nth-child(4)::after,
                &:last-child::after {
                    display: none;
                } 
            }
        }
    }
}