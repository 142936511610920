//======================================================================================================
// Bloc Compte à rebours
//======================================================================================================
.bloc-countdown {
    margin: 20px 0 50px;
    text-align: center;

    &__title {
        font-family: $font-family;
        font-weight: $font-weight-bold;
        font-size: 1.375rem;
        color: $color-main;
    }

    &__date {
        margin: 30px auto;
        display: flex;
        align-items: center;
        justify-content: center;

        &--past {
            .bloc-countdown {
                &__date  {
                    &-bloc {
                        opacity: .5; 
                    }
                }
            }

            +  .bloc-countdown {
                &__message  {
                    display: flex;
                }
            }
        }

        &-bloc {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 150px;
            height: 150px;
            background-color: $color-third;
            border-radius: $border-radius;
            margin: 0 15px;
        }

        &__number {
            font-family: $font-family;
            font-weight: $font-weight-bold;
            color: $color-text;
            font-size: 2.125rem;
            line-height: 2.5rem;
        }

        &__text {
            color: $color-text;
            font-size: 1.125rem;
            text-transform: uppercase;
        }
    }

    &__message {
        display: none;
        flex-direction: column;
        align-items: center;

        ol, ul {
            display: flex;
            flex-direction: column;
            align-items: center; 
        }

        &--visible {
            display: flex;
        }
    }
} 


//======================================================================================================
// Responsive
//======================================================================================================
// 960
@media screen and (max-width: $medium) {
    .bloc-countdown {
        &__date {
            &-bloc {
                margin: 0 10px;
                height: 140px;
                width: 140px;
            }
        }
    }
}

//640
@media screen and (max-width: $small) {
    .bloc-countdown {
        &__date {
            flex-wrap: wrap;
            justify-content: space-between;

            &-bloc {
                &:nth-child(2n+1){
                    margin: 10px auto;
                }

                &:nth-child(2n){
                    margin: 10px 0 10px 20px;
                }
            }
        }
    }
}