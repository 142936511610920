.post_navigation {
    // display: flex;
    // justify-content: center;
    padding: 40px 0;

    .return, .prev, .next {
        a {
            display: flex;
            align-items: center;
            font-family: $btn-font-family;
            font-weight: $btn-font-weight;
            font-size: $btn-font-size;
            line-height: $btn-line-height;
            color: $btn-color;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            border: $btn-border-width $btn-border-style $btn-border-color;
            border-radius: $btn-border-radius;
            padding: $btn-padding;
            transition: all $duration;

            svg, &:after {
                width: 26px;
                height: 26px;
                margin-right: 5px;
                transition: all $duration;
            }

            svg {   
                fill: $btn-color;
            }

            &:after {
                background-color: $btn-color;
            }

            &:hover {
                color: $btn-color--hover;
                background: $btn-bg--hover;
                border-color: $btn-border-color--hover;
        
                svg{
                    fill: $btn-color--hover;
                }

                &:after {
                    background-color: $btn-color--hover;
                }
            }
        }
    }
}

// .navigation_link svg {
//     position: relative;
//     top: 5px;
// }

// Update Upgrades 11/2023
// for all buttons navigations
.navigation_link {
    column-gap: 5px;
}

.post_navigation {
    &--container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        // Si les liens sont cachés, mettre ce style :
        // justify-content: space-evenly;
        width: 100%;
        gap: 30px;
    }

    .return {
        a {
            align-items: center;
        }
    }

    .prev, .next {
        // A adapter en fonction de la width du bouton précédent
        min-width: 184px;
    }

    .prev {
        .navigation_link {
          display: flex;
          justify-content: flex-start;

            a {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-text;
                    mask-image: url( $urlShort + '/ic-nav-arrow-left.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-right: 5px;
                }
            }
        }
    }

    .next {
        .navigation_link {
             display: flex;
            justify-content: flex-end;

          a {
                &:after {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-text;
                    mask-image: url( $urlShort + '/ic-nav-arrow-right.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-left: 5px;
                }
            }
        }
    }

    .prev, .next {
        &:hover, &:focus, &:focus-within {

            .navigation_link {
                color: $color-main;

                svg {
                    fill: $color-main;
                }

                a {
                    color: $color-white;
                    
                    &:before, &:after {
                        background-color: $color-white;
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .post_navigation {

        &--container {
            gap: 20px 0;
        }

        .prev, .next {
            // A changer en fonction de la taille du bouton "suivant"
            min-width: 145px;

            .navigation_link a {
                padding: 15px 20px;
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .post_navigation {

        .prev, .next {
            min-width: auto;

            .navigation_link a {
                padding: 15px 12px;
            }
        }

        .return {
            display: flex;
            justify-content: center;
            order: 1;
            flex: 1 1 100%;
            width: 100%;

            a {
                width: 100%;
                justify-content: center;
            }

        }
    }
}