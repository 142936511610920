.nav-main {
    margin-right: 130px;

    // Main menu
    .menu {
        display: flex;
        column-gap: 130px;
        transition: $duration;

        &__item {
            &:last-child {
                .menu__link::before {
                    display: none;
                }
            }

            &.menu-item-has-children { 
                &.js-active-submenu {
                    .menu__link::after {
                        opacity: 1;
                    }

                    .submenu {
                        display: flex;
                        justify-content: center;
                    }
                }
            }   
        }
        
        &__link {
            height: 80px;
            position: relative;
            display: flex;
            align-items: center;
            font-size: $font-size--5;
            line-height: 1.1em;
            font-weight: 600;
            color: $color-white;
            text-transform: uppercase;
            letter-spacing: 0.05em;

            &::before {
                content: '';
                width: 30px;
                height: 2px;
                position: absolute;
                top: 50%;
                right: -80px;
                transform: translateY(-50%);
                background-color: $color-white;
            }

            &::after {
                content: '';
                width: 30px;
                height: 30px;
                position: absolute;
                left: 50%;
                bottom: -15px;
                transform: translateX(-50%);
                z-index: 2;
                background-color: $color-white;
                border-radius: $border-radius--round;
                opacity: 0;
                transition: $duration;
            }
        }
    }

    // Sub menu
    .submenu {
        width: 100%;
        position: absolute;
        right: 0;
        z-index: 1;
        display: none;
        background: $color-dark;
        padding: 30px 15px;

        &__nav {
            width: 100%;
            column-count: 3;
            column-gap: 30px;
        }

        &__item {
            width: 100%;
            display: inline-flex;
        }

        &__link {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            font-weight: 700;
            color: $color-white;
            border: 1px solid transparent;
            border-radius: 40px;
            padding: 15px 30px;
            transition: $duration;

            &::before {
                content: "";
                width: 20px;
                height: 2px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background-color: $color-white;
                transition: $duration;
            }

            &:hover,
            &:focus {
                padding-left: 45px;
                border-color: $color-white;

                &::before {
                    left: 15px;
                }
            }

            &--grandchild {
                font-weight: 400;
                padding: 12.5px 10px;

                &::before {
                    display: none;
                }

                &:hover,
                &:focus {
                    padding: 12.5px 20px;
                }
            }
        }
    }
    
    // For responsive
    &__button, 
    &__icon, 
    &__close, 
    .submenu__close {
        display: none;
    }
}





//=================================================================================================
// Responsive
//=================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .nav-main {
            margin-right: 60px;

            .menu {
                column-gap: 110px;

                &__link::before {
                    right: -70px;
                }
            }
        }

    }



    // 960
    @media screen and (max-width: $medium) {
        body.js-active-navmain {
            overflow: hidden;
        }    


        .nav-main {
            position: relative;
            margin-right: 0;

            &.js-active-menu {
                .nav-main__container {
                    left: 0;
                }
            }

            &__container {
                width: 100%;
                height: 100vh;
                position: fixed;
                top: 0;
                left: -100vw;
                right: 100%;
                z-index: 990;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                row-gap: 30px;
                background-color: $color-main;
                padding: 40px 0;
                transition: $duration;
            }
            
            &__button {
                position: relative;
                display: flex;
                align-items: center;
                column-gap: 15px;
                font-size: $font-size--5;
                font-weight: 600; 
                color: $color-white;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                background-color: transparent;
                border: 0;
                border-radius: 0;
                padding: 0;
                margin: 0;
                cursor: pointer;
                transition: $duration;

                &:hover, 
                &:focus {
                    color: $color-white;
                    background-color: transparent;
                }
            }

            &__icon {
                width: 20px;
                height: 2px;
                position: relative;
                top: -1px;
                display: block;
                background: $color-white;
                transition: $duration;

                &:before, 
                &:after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    display: block;
                    background: $color-white;
                    transition: $duration;
                }

                &:before {
                    top: 7px;
                }

                &:after {
                    top: -7px;
                } 
            }

            &__close {
                width: 40px;
                height: 40px;
                position: relative;
                z-index: 10;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                border: 1px solid $color-white;
                border-radius: $border-radius--round;
                margin: 0 40px 0 auto;
                padding: 10px;
                transition: $duration;

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        fill: $color-text;
                    }
                }

                svg {
                    width: 12px;
                    height: 12px;
                    fill: $color-white;
                    transition: $duration ;
                }
            }

            .menu {
                width: 100%;
                flex-direction: column;
                overflow-y: auto;
                padding: 0 40px;

                &__item {
                    &.menu-item-has-children {
                        &:focus-within, 
                        &.js-active-submenu{
                            .submenu {
                                justify-content: flex-start;
                            }
                        }

                        &.js-active-submenu {   
                            .submenu {
                                right: 0;
                            }
                        }
                    }

                    &:last-child {
                        .menu__link::before {
                            display: block;
                        }
                    }
                }

                &__link {
                    height: auto;
                    column-gap: 15px;
                    padding: 20px 30px 20px 0;

                    &::before {
                        width: 60px;
                        position: relative;
                        top: unset;
                        right: unset;
                        transform: unset;
                    }

                    &::after {
                        content: '\f345';
                        width: auto;
                        height: auto;
                        font: normal 20px/1 dashicons;
                        right: 0;
                        left: unset;
                        bottom: unset;
                        transform: unset;
                        z-index: 0;
                        color: $color-white;
                        background-color: unset;
                        border-radius: unset;
                        opacity: 1;
                        transition: all $duration;
                    }
                }
            }

            .submenu {
                width: 100vw;
                height: 100vh;
                display: flex;
                flex-direction: column;
                row-gap: 30px;
                top: 0;
                right: 100%;
                z-index: 1;
                background-color: $color-dark;
                padding: 40px 0 60px 0;
                transition: $duration;

                &__close {
                    width: 40px;
                    height: 40px;
                    flex-shrink: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: transparent;
                    border: 1px solid $color-white;
                    border-radius: $border-radius--round;
                    padding: 0;
                    margin: 0 0 0 40px;
                    transition: $duration;

                    &:hover,
                    &:focus {
                        background-color: $color-white;
                        border-color: $color-white;

                        svg {
                            fill: $color-dark;
                        }
                    }

                    svg {
                        width: 32px;
                        height: 32px;
                        fill: $color-white;
                        transition: $duration;
                    }
                }

                &__nav {
                    max-width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding: 0 40px;
                    overflow-y: auto;
                }
            }
        }

        // Admin 
        .admin-bar {
            .nav-main {
                &__container, 
                .submenu {
                    padding-top: 70px;
                }
            }
        }
    }



    // 640
    @media screen and (max-width: $small) {
        .nav-main {
            margin: 0 auto 0 0;

            &__button {
                column-gap: 10px;
            }
        }
    }