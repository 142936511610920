.search-container {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;

    .searchInput {
        box-sizing: border-box;
        background-color: $color-white;
        border: none;
        border-radius: 50px;
        padding: 25px 55px 25px 25px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        &::placeholder {
            color: $color-text;
        }

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }
    }

    .search-svg {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
        display: flex;
        cursor: pointer;

        &:hover,
        &:focus {
            svg {
                fill: $color-main;
            }
        }

        svg {
            width: 20px;
            height: 20px;
            fill: $color-text;
            transition: all $duration;
        }
    }
}

.searchResult {
    position: relative;
    top: -30px;
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    border: none;
    padding-top: 30px;

    li {
        position: relative;
        border-bottom: 1px solid $color-dark;
        transition: $duration;

        &:first-child {
            border-top: 1px solid $color-dark;
        }

        &:last-child {
            border-bottom: 0;
        }

        a {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 15px 25px;
            transition: $duration;
        }

        // Category
        .resultCPT {
            position: absolute;
            top: 50%;
            right: 25px;
            transform: translateY(-50%);
            color: $color-main;
            pointer-events: none;
            transition: all $duration;
        }

        // No search results
        &.no-results {
            span {
                width: 100%;
                height: 100%;
                display: flex;
                padding: 15px 25px;
                color: $color-text;
            }
        }

        // Search results
        &:not(.no-results) {
            &.selected,
            &:hover,
            &:focus {
                background-color: $color-main;
                border-color: $color-main;

                .resultCPT {
                    color: $color-light;
                }
            }

            a {
                &:hover,
                &:focus {
                    color: $color-white;
                    padding-left: 35px;

                    &::after {
                        color: $color-white;
                    }
                }
            }
        }

        &.selected {
            a {
                color: $color-text;
                padding-left: 35px;

                &::after {
                    color: $color-text;
                }
            }
        }

        // Show all results
        &.show-all a {
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            text-transform: uppercase;

            &::after {
                content: "\f344";
                height: 100%;
                position: relative;
                display: flex;
                align-items: center;
                font: normal 18px/1 dashicons;
                color: $color-text;
                margin-left: 10px;
                transition: all $duration;
            }
        }
    }
}

body.search-open {
    overflow: hidden;
}

body.search {
    .breadcrumb {
        display: none;
    }
    .container--search {
        padding-top: 20px;
    }
}





.search-popup {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 15;
    background: $color-bg--neutral;
    transition: $duration;

    &::after {
        content: '';
        width: 220px;
        height: 220px;
        position: absolute;
        left: -70px;
        bottom: -70px;
        background-color: rgba($color-white, .5);
        border-radius: $border-radius--round;
    }

    .search-container {
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    .container {
        width: 100%;
        height: 100%;
        position: relative;
        
        .search-popup__content {
            position: relative;
            top: 30%;
            display: flex;
            flex-direction: column; 
            justify-content: center;  
        }

        label {
            width: calc(100% - 200px);
            position: relative;
            display: flex;
            align-items: center;
            font-family: $font-family--heading;
            font-size: $font-size--1;
            font-weight: 400;
            color: $color-text;
            margin: 0 auto 20px auto;

            &::before {
                content: '';
                width: 40px;
                height: 40px;
                position: absolute;
                top: 0;
                left: -20px;
                z-index: -1;
                background-color: $color-second;
                border-radius: $border-radius--round;
            }
        }

        .search-container {
            width: calc(100% - 200px);
            display: flex;
            align-items: center;
            margin: 0 auto;
        }

        .searchResult {
            width: calc(100% - 200px);
            left: 100px;
        }
    }

    .search-close {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 5%;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $color-main;
        border-color: $color-main;
        border-radius: 50%;
        padding: 0;
        cursor: pointer;
        transition: $duration;
        
        svg {
            width: 25px;
            height: 25px;
            fill: $color-white;
            transition: all $duration;
        }

        &:hover,
        &:focus {
            background: $color-dark;
            border-color: $color-dark;

            svg {
                fill: $color-white;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .search-popup .container .search-container,
        .search-popup .container label {
            width: calc(100% - 140px);
        }

        .search-popup .container .searchResult {
            width: calc(100% - 140px);
            left: 70px;
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        .search-popup .container .search-container,
        .search-popup .container label {
            width: 100%;
        }

        .search-popup .container .searchResult {
            width: 100%;
            left: 0;
        }

        .search-popup .container .search-popup__content {
            top: 20%;
        }
    }


    // 640
    @media screen and (max-width: $small) {
        .searchResult li .resultCPT {
            display: none;
        }

        .search-popup .container .search-popup__content {
            top: 15%;
        }

        .search-popup .container label {
            font-size: 2.5em;
        }
    }