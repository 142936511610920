.cover {
    margin-bottom: 30px;

    &__image {
        width: 100%;
        height: 500px;
        background: $color-bg--image;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 50px;
    }

    &__title {
        margin: 0;
    }

    &__text {
        font-size: $font-size--5;
        line-height: 1.4em;
        margin-top: 17.5px;
    }
}



//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .cover {             
            &__image {
                height: 400px;
            }

            &__content {
                margin-top: 40px;
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        .cover {      
            &__image {
                height: 320px;
            }
        
            &__content {     
                margin-top: 30px;
            }
        }
    }



    // 640
    @media screen and (max-width: $small) {
        .cover {       
            &__image {
                height: 280px;
            }

            &__title::before {
                width: 30px;
                height: 30px;
                left: -15px;
            }
        }
    }