body.admin-bar .header-bottom--fixed {
    top: 32px;
}

body.sticky #content {
    margin-top: 80px;
}

.header {
    width: 100%;
    position: relative;
    z-index: 990;

    &-top {
        width: 100%;
        height: 155px;
        background-color: $color-bg--body;

        &__container {
            height: 100%;
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    &-bottom {
        width: 100%;
        height: 80px;
        background-color: $color-main;

        &--fixed {
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            filter: drop-shadow(1px 2px 20px rgba(0, 0, 0, 0.05));

            .header-bottom__home {
                opacity: 1;
                visibility: visible;
            }
        }

        &__container {
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        &__home {
            width: 40px;
            height: 40px;
            border: 1px solid $color-white;
            border-radius: $border-radius--round;
            margin: 0 auto 0 0;
            opacity: 0;
            visibility: hidden;
            transition: $duration;

            &:hover,
            &:focus {
                background-color: $color-white;
                border-color: $color-white;

                svg {
                    fill: $color-text;
                }
            }

            svg {
                width: 100%;
                height: 100%;
                fill: $color-white;
                transition: $duration;
            }
        }
    }
}



.main-logo {
    width: 182px;  
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
        width: 100%;
        height: 100%;
    }

    &__title {
        display: none;
    }
}



.socials {
    display: flex;
    column-gap: 15px;

    &--mobile {
        display: none;

        .socials__item {
            &:hover,
            &:focus {
                background-color: $color-white;

                svg {
                    fill: $color-text;
                }
            }
        }
    }

    &__item {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $color-text;
        border-radius: $border-radius--round;
        transition: $duration;

        &:hover,
        &:focus {
            background-color: $color-text;

            svg {
                fill: $color-white;
            }
        }

        svg {
            width: 34px;
            height: 34px;
            fill: $color-text;
            transition: $duration;
        }
    }
}



.tools {
    display: flex;
    column-gap: 20px;

    #accessconfig {
        width: 40px;
        height: 40px;
    }

    &__item {
        width: 40px;
        height: 40px;
        border: 1px solid $color-white;
        border-radius: $border-radius--round;
        padding: 0;
        margin: 0;
        transition: $duration;

        &:hover,
        &:focus {
            background-color: $color-white;
            border-color: $color-white;

            svg {
                fill: $color-text;
            }

            span {
                color: $color-text;
            }
        }

        svg {
            width: 100%;
            height: 100%;
            fill: $color-white;
            transition: $duration;
        }

        &--search {
            width: 180px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 0;
            border-radius: 50px;
            padding: 0 15px;

            &--disabled {
                opacity: .2;
                pointer-events: none;
            }

            svg {
                width: 40px;    
                height: 40px;
            }

            span {
                font-size: $font-size--text-small;
                line-height: 1.45em;
                font-weight: 500;
                color: $color-white;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                transition: $duration;
            }
        }
    }
}





//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {                      
        .tools {
            column-gap: 15px;
        
            &__item--search {
                width: 170px;
                padding: 0 10px;
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        .header-bottom {
            &__container {
                justify-content: space-between;
            }

            &__home {
                display: none;
            }
        }
    }



    // 640
    @media screen and (max-width: $small) {
        .header-top__container {
            justify-content: center;
        }

        .header-bottom__container {
            justify-content: flex-end;
            column-gap: 10px;
        }


        .main-logo {
            position: relative;
            top: unset;
            left: unset;
            transform: unset;
        }


        .socials {
            display: none;
            column-gap: 10px;

            &--mobile {
                display: flex;
            }

            &__item {
                width: 35px;
                height: 35px;
                border-color: $color-white;

                svg {
                    width: 30px;
                    height: 30px;
                    fill: $color-white;
                }
            }
        }


        .tools {
            column-gap: 10px;

            #accessconfig {
                width: 35px;
                height: 35px;
            }

            &__item {
                width: 35px;
                height: 35px;

                &--search {
                    width: 35px;
                    justify-content: center;
                    border-radius: $border-radius--round;
                    padding: 0;

                    svg {
                        width: 100%;
                        height: 100%;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }
    }
