.wrapper {
    #comarquage {
        margin-top: 30px;
        color: $color-text;
        overflow: inherit;


        //======================================================================================================
        // Base
        //======================================================================================================
        h1, h2, h3, h4, h5, h6 {
            font-family: $font-family;
            font-weight: $font-weight--heading;
            line-height: $line-height--heading;
        }

        h1 {
            font-family: $font-family--heading;
        }

        p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, button, input, &:before, &:after {
            font-family: $font-family;
            line-height: $line-height;
            font-size: $font-size--text;
        }


        //======================================================================================================
        // Main
        //======================================================================================================
        #co-page {

            // Lists
            ul, ul.list-puce, ul.list-arrow {
                list-style: none;
                text-align: left;
                margin: 20px;

                li {
                    position: relative;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    box-sizing: inherit;
                    margin: 10px 0;
                    padding: 0 0 0 20px;
                    font-size: $font-size--text;
                }
            }

            ul.list-puce {
                margin: 20px 0 20px 20px;
            }

            ul.list-arrow {
                margin: 0 0 0 20px;
            }

            ul.list-top-dotted li {
                border-top: 0;
            }


            // Tabulations
            .nav-tabs {
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;
                margin: 0;

                li {
                    margin: 0 10px 0 0;
                    padding: 0;

                    &::before {
                        display: none;
                    }

                    span {
                        margin: 0;
                        padding: 10px 20px;
                        background-color: $color-bg--neutral;
                        border: 0;
                        border-radius: $border-radius $border-radius 0 0;
                        transition: background-color ease-in-out $duration;
                    }
                }
            }


            // Card service
            .service-in-content {
                background: $color-bg--neutral;
                margin: 10px 0 20px 0;
                padding: 40px 30px 35px 30px;
                border-radius: $border-radius;

                &::before {
                    border-color: $color-bg--body transparent transparent;
                    z-index: 1;
                }

                .co-bloc-title .title-icons svg {
                    fill: $color-text;
                }
            }

            .bloc-serviceenligne-index {
                h2 {
                    padding: 0;
                    border-bottom: 0;
                    font-size: $font-size--4;
                }
                .list-arrow {
                    margin: 20px 0;

                    li {
                        margin: 10px 0 10px 20px;
                        padding: 0 0 0 20px;
                    }
                }
            }


            // Buttons
            .co-btn {
                &.co-btn-primary {   
                    &:hover,
                    &:focus,
                    &:active {
                        text-decoration: none;
                    }
                }
            }

            button svg {
                width: 20px;
                height: 20px;
            }


            // Radio input
            .bloc-cas-radio  {
                .choice-tree-choice-list li.choice-tree-choice {
                    display: block;
                    margin: 0;
                    padding: 20px 10px;
    
                    .btn-like-radio {
                        display: flex;
                        text-align: left;
                        align-items: center;
                        justify-content: flex-start;
                        width: 100%;
        
                        .radio-icon-not-active {
                            display: flex;
                            align-items: center;
                        }
        
                        svg {
                            fill: $color-bg--transparent;
                            stroke: $color-text;
    
                            &:hover {
                                fill: $color-text;
                            }
                        }
    
                        &:hover, &:focus {
                            svg {
                                fill: $color-text;
                            }
                        }
        
                        &.active {
                            .radio-icon-active {
                                display: flex;
                                align-items: center;
                            }
                        }
                    }
    
                    &.choice-active {
                        >.co-radio-head {
                            border-color: $color-text;
                            background-color: $color-bg--neutral;
                        }
                        .btn-like-radio {        
                            svg {
                                fill: $color-white;
                            }
                        }
                    }
                }
            }

        }


        // list radio
        .bloc-cas-radio {
            > ul {
                > li {
                    &::before {
                        display: none;
                    }
                }
            }
        }        


        //======================================================================================================
        // Buttons
        //======================================================================================================
        .co-btn {
            &.co-btn-primary {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                appearance: none;
                -webkit-appearance: none;
                cursor: pointer;
                margin: 15px 0 5px 0;
                padding: $btn-padding;
                background: $btn-bg;
                border: $btn-border-width $btn-border-style $color-main;
                border-radius: $btn-border-radius;
                font-family: $btn-font-family;
                font-weight: $btn-font-weight;
                font-size: $btn-font-size;
                line-height: $btn-line-height;
                color: $btn-color;
                text-transform: $btn-text-transform;
                text-decoration: none;
                text-align: center;
                transition: all $duration ease;

                svg {
                    fill: $color-white;
                    transition: all $duration ease;
                }

                &:hover,
                &:focus,
                &:active {
                    color: $btn-color--hover !important;
                    background: $btn-bg--hover;
                    border-color: $btn-border-color--hover;
                    text-decoration: none;

                    svg {
                        fill: $color-text;
                        transition: all $duration ease;
                    }
                }
            }
        }


        //======================================================================================================
        // Special style
        //======================================================================================================

        &.espace-part,
        &.espace-pro,
        &.espace-asso {

            // Buttons & Inputs
            .co-btn {
                &.co-btn-primary {
                    background: $color-main;

                    &:hover {
                        background: $color-bg--transparent;
                        text-decoration: none;
                    }
                }

                &.co-btn-slide {
                    .chevron-down, .co-external-link {
                        border-color: $color-text;

                        svg {
                            fill: $color-text;
                        }
                        
                    }

                    &:hover {
                        .chevron-down, .co-external-link {
                            background-color: $color-bg--transparent;
                        }

                        .chevron-down {
                            border-color: $color-bg--transparent;

                            svg {
                                background-color: $color-bg--transparent;
                                fill: $color-text;
                                transition: all $duration ease-in-out;
                            }
                        }
                        .co-external-link svg {
                            fill: $color-white;
                        }
                    }

                    &.active {
                        color: $color-text;
                    }

                }
            }


            // Main
            #co-page {

                // Card
                .co-row {

                    // Card title
                    h2, h3 {
                        color: $color-text;
                    }
                }


                // Card link archive
                &.comarquage-home {

                    .co-home-theme {
                        .home-theme-list {
                            .home-theme-list-item {
                                h3 {
                                    a {
                                        color: $color-text; 
                                        
                                        &:hover, &:focus {
                                            color: $color-text; 
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // Card button
                    .co-panel-body p.link-all {
                        a {
                            color: $btn-color;

                            &:hover,
                            &:focus,
                            &:active {
                                color: $btn-color--hover;
                            }
                        }
                    }
                }


                // Links
                a {
                    color: $color-text;

                    &:hover {
                        color: $color-text;
                    }
                }


                // Breadcrumb
                .co-breadcrumb a {
                    color: $color-text;

                    &:hover, &:focus {
                        color: $color-text;
                    }

                    &.last {
                        color: $color-text;
                    }
                }


                // Table
                .table {
                    thead td {
                        background-color: $color-main !important;
                    }
                }


                // Tabs
                .tabs {
                    .nav-tabs > li {
                        &.active > span {
                            background-color: $color-main;
                            border-color: $color-main;
                        }
                        
                        > span {
                            &:hover, &:focus {
                                background-color: $color-main;
                                transition: all ease-in-out $duration;
                            }
                        }
                    }

                    .tab-content {
                        border-color: $color-text;
                    }
                }

                .fiche-bloc .fiche-item .fiche-item-content {
                    .tabs {
                        .tab-content {
                            padding: 30px;
                            border-color: $color-text;
                            border-radius: $border-radius;
                            border-top-left-radius: 0;
                        }
                    }
                }


                // Input radio
                .bloc-cas-radio .choice-tree-choice-list li.choice-tree-choice {
                    &.choice-active > .co-radio-head button {
                        color: $color-text;
                    }  
                }
                .bloc-cas-radio .choice-tree-choice-list li.choice-tree-choice {
                    .btn-like-radio.active .radio-icon-active svg {
                        fill: $color-text;
                    }
                }


                // Panel
                .co-panel {    
                    h2, h3 {
                        color: $color-text;
                    }
                }


                // Bloc info
                .bloc-asavoir,
                .bloc-anoter,
                .bloc-attention {
                    margin: 20px 0;
                    padding: 20px 20px 10px 20px;
                    background-color: $color-bg--neutral;
                    border: 0;
                    border-radius: $border-radius;

                    .bloc-attention-title, .bloc-asavoir-title, .bloc-anoter-title {
                        color: $color-text;

                        svg {
                            fill: $color-text;
                        }
                    }
                }
                .service-in-content {
                    .bloc-asavoir,
                    .bloc-anoter,
                    .bloc-attention {
                        background-color: $color-white;
                    }
                }


                // Lists 
                ul.list-puce li::before, ol.list-puce li::before {
                    color: $co-list-style;
                }


                // Card
                .service-in-content .co-numerocerfa {
                    color: $color-text;
                    font-weight: $font-weight-bold;
                }
            
            }

        }


        //======================================================================================================
        // Row / Col
        //======================================================================================================
        #co-page {
            .co-row {
                &:not(.co-row-without-antimargin) {
                    display: flex;
                    align-items: stretch;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin: 30px 0 0 0;
                }

                [class*='co-col-'].co-col-border {
                    border: 0;
                }
    
                &::before, &::after {
                    display: none;
                }

                .co-col-1-2 {
                    padding-top: 30px;

                    &:first-child, &:nth-child(2) {
                        padding-top: 0;
                    }

                    &:first-child, &:nth-child(4), &:nth-child(7), &:nth-child(10), &:nth-child(13) {
                        padding-left: 0;
                        padding-right: 15px;
                    }

                    &:nth-child(2), &:nth-child(5), &:nth-child(8), &:nth-child(11), &:nth-child(14) {
                        padding-right: 0;
                        padding-left: 15px;
                    }
                }
    
                .co-col-2, .co-col-4 {
                    margin-bottom: 30px;
                    padding: 20px;
                    background-color: $color-bg--neutral;
                    border-radius: $border-radius;
                }
    
                .co-col-2 {
                    width: 31.5%;
                    margin-right: 30px;
    
                    &:nth-child(3) {
                        margin-right: 0;
                    }
                }
                
                .co-col-4 {
                    width: 100%;
                }
            } 
        }

    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .wrapper #comarquage {
        #co-page {
            .co-row {
                .co-col-2, .co-col-4 {
                    width: 48%;
                    margin-right: 0;
                }  
            } 
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .wrapper #comarquage {
        #co-page {
            .co-row {
                .co-col-2, .co-col-4 {
                    width: 100%;
                }  
            } 
        }
    }

}


// 768
@media screen and (max-width: 768px) {
    .wrapper #comarquage {
        #co-page {
            ul.list-arrow {
                margin: 0 0 0 5px;
            }       
            
            .co-row {
                .co-col-1-2 {
                    padding-top: 0;
    
                    &:first-child, &:nth-child(2) {
                        padding-top: 0;
                    }
    
                    &:first-child, &:nth-child(4), &:nth-child(7), &:nth-child(10), &:nth-child(13) {
                        padding-left: 0;
                        padding-right: 0;
                    }
    
                    &:nth-child(2), &:nth-child(5), &:nth-child(8), &:nth-child(11), &:nth-child(14) {
                        padding-right: 0;
                        padding-left: 0;
                    }
                }
            }

            .nav-tabs > li {
                width: auto;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {
    .wrapper #comarquage {
        #co-page {

            // Lists
            ul.list-puce {
                margin: 20px 0 20px 10px;
            }
            ul, ul.list-arrow {
                margin: 20px 0;
            }

            // Tabulations
            .nav-tabs {
                li {
                    width: 100%;
                    margin: 5px 0 0 0;
                    padding: 0;

                    span {
                        width: 100%;
                    }
                }
            }

        }
    }

}
